'use strict';

var quickview = require('../quickview');
var bonusProductsView = require('../bonus-products-view');
var TPromise = require('promise');
var util = require('../util');
var addresses = SitePreferences.ENABLE_ENHANCED_CHECKOUT ? require('./checkout/opcaddress_enhanced') : require('./checkout/opcaddress');
var formPrepare = require('./checkout/formPrepare');
var cart = require('./cart');
var opcpayment = require('./checkout/opcpayment');
var loadingIndicator = require('../components/LoadingIndicator');
var GiftCard = require('./checkout/GiftCardPaymentPanel');
var GoogleTagManagerClient = require('../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var recaptcha = require('../components/Recaptcha');
var RewardsCredit = require('./checkout/RewardsCredit');
var LoyaltyCredit = require('./checkout/LoyaltyCredit');
var CheckoutConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/CheckoutConstants');
var PaymentMethodConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/PaymentInstrumentConstants').PaymentMethods;
var SiteConstants = require('../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');

var cartItemsFormSelector = 'form#cart-items-form';
var billingAddressFormSelector = '#billingAddressForm';
var $billingAddressForm = $(billingAddressFormSelector);
var shippingAddressFormSelector = '#shippingAddressForm';
var $shippingAddressForm = $(shippingAddressFormSelector);
var paymentSectionSelector = '.form-checkout.payment-section';
var $paymentSection = $(paymentSectionSelector);
var paymentFormSelector = '#paymentSection';
var $paymentForm = $(paymentFormSelector);
var submitPaymentSelector = '.payment-section-footer button[name*="_submitPayment"]:not(#submitOPCPaymentPrivateLabel)';
var $submitPayment = $(submitPaymentSelector);
var submitPrivateLabelPaymentSelector = '#submitOPCPaymentPrivateLabel';
var $submitPrivateLabelPayment = $(submitPrivateLabelPaymentSelector);
var basketContainerSelector = '.basket-container';
var $basketContainer = $(basketContainerSelector);

var placeOrderRecaptcha;
var placeOrderRecaptchaPaypal;
var placeOrderRecaptchaAffirm;
var placeOrderRecaptchaPrivateLabel;
var options = {};
var loaded = {};

// Credit Card inputs & formatting
var creditCardListSelector = '#opc-cc-list input[type="radio"]';
var $creditCardList = $(creditCardListSelector);
var savedCreditCardListSelector = '#creditCardList';
var $savedCreditCardList = $(savedCreditCardListSelector);

var creditCardNumber;
var privateLabelCardNumberInputSelector = 'input[name*="_privateLabelCredit_number"]';
var $privateLabelCardNumberInput = $(privateLabelCardNumberInputSelector);
var creditCardNumberInputSelector = 'input[name*="_creditCard_number"]';
var $creditCardNumberInput = $(creditCardNumberInputSelector);

var creditCardNumberMaskSelector = '.cc-number-mask';
var $creditCardNumberMask = $('<input />').attr({ 
	'class': 'input-text field cc-number-mask', 
	'type': 'text', 
	'maxlength': '19', 
	'pattern': '[0-9 ]+', 
	'inputmode': 'numeric',
	'placeholder': 'Example: 4111111111111111', 
	'autocomplete': 'cc-number', 
	'autocorrect': 'off' 
});
var $creditCardImage = $('<div />', { 'class': 'cc-image' });

/**
 * @description Events should be divided to modules. This allows reinitialize events by sections after partial reload
 * @private
 * @function
 * @description Initializes events for the gift registration
 */
function initializeEvents(options) {
	initializeCartEvents(options);
	initializePaymentMethodsEvents();
	
	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});
}

function initializeCartEvents(options) {
    $(document).on('click', 'a.add-to-wishlist', function (e) {
    	/*remove next line to use default dw wishlist functionality*/
    	return false;
    	e.preventDefault();

    	if(Customer.authenticated == true){
    		//post form here
    	} else {
    		$('html, body').animate({ scrollTop: $('.login-form').eq(0).offset().top}, 1000);
    	}
    });
    
    if (SitePreferences.ALTERNATE_PICKUP_ENABLED) {
		renderAlternatePickup();
	}

	$submitPayment.on('click', function(e) {
		e.preventDefault();
		
		if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPlaceOrder) {
			submitPayment();
		}
    });

	$(document).on('Checkout.SubmitPayment', function () {
		if (options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder) {
			submitPayment();
		}
	});
	
    $submitPrivateLabelPayment.on('click', function(e){
    	e.preventDefault();
    	
    	if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForPlaceOrder) {
			submitPaymentPrivateLabel();	
		}
    });
    
    $(document).on('Checkout.SubmitPaymentPrivateLabel', function () {
    	if (options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder) {
			submitPaymentPrivateLabel();
		}
	});
    
    var getCreditCardInfo = function (id) {
        return TPromise.resolve($.ajax({
            type: 'GET',
            url: util.ajaxUrl(Urls.billingSelectCC),
            data: {ccID: id}
        }));
    };

    $(document).on('change', savedCreditCardListSelector, function() {
    	var ccID = $(this).val();
    	if (ccID.length <= 0) {
    		return;
    	}
    	getCreditCardInfo(ccID).then(function (response) {
			var data = response;
			if (data.hasOwnProperty('maskedNumber')) {
				$('#dwfrm_billing_paymentMethods_creditCard_owner').val(data.holder).valid();
				$('#dwfrm_billing_paymentMethods_creditCard_type[value="'+data.type+'"]')
					.first()
					.prop('checked', true)
					.trigger('change');
				$("[id^='dwfrm_billing_paymentMethods_creditCard_number']").first().val(data.maskedNumber).valid();
				$creditCardNumberMask.val(data.maskedNumber);
				$('#dwfrm_billing_paymentMethods_creditCard_expiration_month').val(data.expirationMonth).trigger('change');
				$('#dwfrm_billing_paymentMethods_creditCard_expiration_year').val(data.expirationYear).trigger('change');
				$('input[name^=dwfrm_billing_paymentMethods_creditCard_cvn]').val('');
			}
		}.bind(this));

    });

    //preselect default credit card for saved credit cards
	if($savedCreditCardList.length > 0 && $('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val() == PaymentMethodConstants.CreditCard) {
		$savedCreditCardList.find('option').eq(1).prop('selected', true);
		$savedCreditCardList.trigger('change');
	}

    var getPrivateLabelCardInfo = function (id) {
        return TPromise.resolve($.ajax({
            type: 'GET',
            url: util.ajaxUrl(Urls.billingSelectPrivateLabel),
            data: {cardId: id}
        }));
    };

    $(document).on('change', '#privateLabelCardList', function() {
    	var cardId = $('#privateLabelCardList').val();
    	if (cardId.length <= 0) {
    		return;
    	}
    	getPrivateLabelCardInfo(cardId).then(function (response) {
			var data = response;
			if (data.hasOwnProperty('maskedNumber')) {
				$privateLabelCardNumberInput.first().val(data.maskedNumber).valid();
			}
		}.bind(this));

    });

    addresses.init(options);
    cart.init(options);
}

function renderAlternatePickup() {
	$(document).off('click', 'button.alt-pickup-button').on('click', 'button.alt-pickup-button', function (e) {
    	e.preventDefault();
		$('#alt-pickup-modal').addClass('active-modal').show();
		$('fieldset span').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).off('click', '#alt-pickup-modal .close-modal').on('click', '#alt-pickup-modal .close-modal', function (e) {
    	e.preventDefault();
		$('#alt-pickup-modal').removeClass('active-modal').hide();
    });
    
    $(document).on('focus', 'input[name="firstName"]', function () {
		$('.firstname-error').removeClass('error-msg');
    });
    
    $(document).on('focus', 'input[name="lastName"]', function () {
		$('.lastname-error').removeClass('error-msg');
    });
    
    $(document).on('focus', 'input[name="email"]', function () {
		$('.email-error').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).on('focus', 'input[name="phone"]', function () {
		$('.phone-error').removeClass('error-msg');
		$('.one-required').removeClass('error');
    });
    
    $(document).off('click', 'button#altPickupSubmit').on('click', 'button#altPickupSubmit', function (e) {
    	e.preventDefault();
		
    	var isFirstNameValid = true;
		var firstName = $('input[name="firstName"]').val();
		var regexName = /^[a-z ,.'-]+$/i;
		if (firstName.length == 0 || firstName.match(regexName) == null) {
			isFirstNameValid = false;
			$('.firstname-error').addClass('error-msg');
		}
		
		var isLastNameValid = true;		
		var lastName =$('input[name="lastName"]').val();
		if (lastName.length == 0 || lastName.match(regexName) == null) {
			isLastNameValid = false;
			$('.lastname-error').addClass('error-msg');
		}
		
		var isEmailValid = true;		
		var email = $('input[name="email"]').val();
		var regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
		if (email.length > 0 && email.match(regexEmail) == null) {
			isEmailValid = false;
			$('.email-error').addClass('error-msg');
		}

		var isPhoneValid = true;
		var phone = $('input[name="phone"]').val().replace(/\D/g, '');
		var regexPhone = /^\+?\(?([0-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/g;
		if (phone.length > 0 && phone.match(regexPhone) == null) {
			isPhoneValid = false;
			$('.phone-error').addClass('error-msg');
		}
		
    	if (email.length == 0 && phone.length == 0) {
    		$('.one-required').addClass('error');
    		isEmailValid = false;
    		isPhoneValid = false;
    	}
    	
    	if (!isEmailValid || !isPhoneValid) {
    		return;
    	}
    	
		if (isFirstNameValid && isLastNameValid && (isEmailValid || isPhoneValid)) {
			var cleanPhone = phone.replace(/\D/g, '');
			var shipmentID = $('.alternate-pickup').closest("form").attr('shipment');
			
			$.ajax({
				url: Urls.setAlternatePickup,
				type: "get",
				data: {
					shipmentID: shipmentID,
					altPickupFirstName: firstName,
					altPickupLastName: lastName,
					altPickupEmail: email,
					altPickupPhone: cleanPhone
				},
				success: function (response) {
					var data = JSON.parse(response);
						
					if (data.status == 'success') {
						$('span.alt-person').attr('data-firstname', firstName).attr('data-lastname', lastName).attr('data-email', email).attr('data-phone', cleanPhone);
						displayAlternatePickup (firstName + ' ' + lastName)
						$('#alt-pickup-modal').removeClass('active-modal').hide();
					}
				},
				error: function(jqXHR, textStatus, errorThrown) {
					console.log(textStatus, errorThrown);
				}
			});
		}
    });
}

function displayAlternatePickup (fullName) {
	$('.alternate-pickup span.alt-person').text(fullName);
	$('span.alt-header').show();
	$('button.alt-pickup-button').text('UPDATE');
}

function renderRecaptcha() {
	if (loaded.Recaptcha && options && options.Recaptcha && options.Recaptcha.EnabledForPlaceOrder && options.Recaptcha.GoogleSiteKey) {
		if ($('#submitOPCPayment').length > 0) {
			placeOrderRecaptcha = grecaptcha.render('submitOPCPayment', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPayment,
		        badge: 'bottomleft'
		    });
		}
		
		if ($('#submitOPCPaymentPaypal').length > 0) {
			placeOrderRecaptchaPaypal = grecaptcha.render('submitOPCPaymentPaypal', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPayment,
		        badge: 'bottomleft'
		    });
		}
		
		if ($('#submitOPCPaymentForAffirm').length > 0) {
			placeOrderRecaptchaAffirm = grecaptcha.render('submitOPCPaymentForAffirm', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPayment,
		        badge: 'bottomleft'
		    });
		}
		
		if ($('#submitOPCPaymentPrivateLabel').length > 0) {
			placeOrderRecaptchaPrivateLabel = grecaptcha.render('submitOPCPaymentPrivateLabel', {
		        sitekey: options.Recaptcha.GoogleSiteKey,
		        callback: submitPaymentPrivateLabel,
		        badge: 'bottomleft'
		    });
		}
	}
}

function loyaltyOptin() {
	if (SitePreferences.LOYALTY_ENABLED) {
		var opt_email = $('.billing-email-address').text();
		var opt_status = $('#loyalty_optin').prop('checked');
		var dw_customer_id = (Customer.authenticated == true) ? $('.section-checkout').attr('data-customerid') : '';
		
		if (SitePreferences.CORDIAL_ENABLED) {
			if (opt_status == false) {
				// track Cordial loyalty optout event
				var auth_data = {
					email: opt_email
				}
				
				var contact_data = {
				    'channels': {
				        'email': {
				            'address': opt_email         
				        }
				    }
				};
				
				var properties = {
				    'email': opt_email
				};
				
				if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
					crdl([
						['contact', auth_data, contact_data],
						['event', 'loyalty_optout', properties]
					]);	
				}
			}
		}
		
		return TPromise.resolve($.ajax({
			url: Urls.loyaltyOptin,
			dataType: 'JSON',
			method: 'POST',
			data: {
				'email': opt_email,
				'dwCustomerID': dw_customer_id,
				'optStatus': opt_status
			},						
			success : function(data) {
				if (('error' in data && data['error'])) {
					console.log(data.message);
				}
			}
		}));
	} else {
		TPromise.resolve();
	}
}

function submitPayment() {
	var balanceDue = parseFloat($paymentSection.attr('balance-due'));
	$submitPayment.filter(':visible').loading({
		IsImageShown: false,
		Label: Resources.Loading.Checkout.PlacingOrder
	});
	
	setPlaceOrderButtonsDisabled(true);
	
	$billingAddressForm.validate().form();
	$shippingAddressForm.validate().form();
	
	var $creditCardType = $paymentForm.find('[name$="_creditCard_type"]:checked').val();
	if (balanceDue > 0) {
		$paymentForm.validate().form();
		
		if (typeof $creditCardType === 'undefined') {
    		$paymentForm.find('[name$="_creditCard_type"]').closest('div.form-row').find('div.field-wrapper.error').remove();
    		setTimeout(function(){
    			$paymentForm.find('[name$="_creditCard_type"]').closest('div.form-row').append('<div class="field-wrapper error" style="height:0px;"><span class="error">' + Resources.REQUIRED_FIELD + '</span></div>');
    		}, 50);
    	}
	}
	
	//check first if addresses are valid
	var isBillingAddressValid = false;
	var isShippingAddressValid = false;
	
	$billingAddressForm.validate().settings.ignore = [];
	$shippingAddressForm.validate().settings.ignore = [];
	
	if ($billingAddressForm.valid()) {
		isBillingAddressValid = true;
	} else {
		$('.billing-address-data.form-info').hide();
		
		$billingAddressForm.show();
		$billingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if ($shippingAddressForm.valid()) {
		isShippingAddressValid = true;
	} else {
		$('.shipping-address-data.form-info').hide();
		
		$shippingAddressForm.show();
		$shippingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if (isBillingAddressValid && isShippingAddressValid && $('#shipping-method-list input:checked').length && (balanceDue == 0 || $paymentForm.valid())) {
		$('.payment-declined').remove();
		
		var actionName = $submitPayment.filter(':visible').attr('name');
		setOPCPaymentData($paymentForm, actionName).then(function (response) {
			var data;
            try {
				data = JSON.parse(response);
			} catch (e) {
				data = response;
			}

			if (data.hasOwnProperty('status') && data.status === 'success') {
				GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo').then(loyaltyOptin).then(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				}).catch(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				});
			} else if (data.hasOwnProperty('status') && data.status === 'affirmRedirect') {
				var _affirm_config = {
					public_api_key: data.affirm.PublicApiKey,
				    script: data.affirm.Script
				};
				(function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,_affirm_config,"affirm","checkout","ui","script","ready");
				
				affirm.checkout(JSON.parse(data.affirm.AffirmCheckout));
				affirm.checkout.post();
				affirm.ui.ready(
				    function() {
				        affirm.ui.error.on("close", function(){
				            // window.location.replace(data.affirm.RedirectUrl);
				        });
				    }
				);
			} else if (data.hasOwnProperty('status') && data.status === 'shippingRestrictionsError'){
				var popup = require('../popup');
				var payload = JSON.parse(data.payload);

				var list = $.map(payload.list, function (el) {
					return '<li>' + el.name + ':<br>' + el.message + '</li>';
				}).join('');
				
				popup.init({
					title: payload.title,
					content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
				}).showPopup();
			} else if (data.hasOwnProperty('status') && data.status === 'BillingAddressError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Billing Address Error:</b> ' + message + '</div>');
			} else if (data.hasOwnProperty('status') && data.status === 'ShippingAddressError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Shipping Address Error:</b> ' + message + '</div>');
			} else if (data.hasOwnProperty('status') && data.status === 'ValidationError') {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2"><i class="fa fa-exclamation-triangle"></i> <b>Validation Error:</b> ' + message + '</div>');
			} else {
				var message = data.hasOwnProperty('message') ? data.message : Resources.Checkout.Confirm.Error.Generic;
				$submitPayment.after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Payment Declined:</b> ' + message + '</div>');
			}
			
			$submitPayment.filter(':visible').loading('destroy');
			setPlaceOrderButtonsDisabled(false);
			if (placeOrderRecaptcha != undefined) {
				grecaptcha.reset(placeOrderRecaptcha);
			}
			
			if (placeOrderRecaptchaPaypal != undefined) {
				grecaptcha.reset(placeOrderRecaptchaPaypal);
			}
			
			if (placeOrderRecaptchaAffirm != undefined) {
				grecaptcha.reset(placeOrderRecaptchaAffirm);
			}
		}).catch(function(response) {
			if (response.status == 302 && response.responseText) { 
				location.href = response.responseText;
			}
		});
	} else {
		$submitPayment.filter(':visible').loading('destroy');
		setPlaceOrderButtonsDisabled(false);

		// Adding ADA compliance error messages for submitPayment section
		$paymentForm.validate().form();
		if ($paymentForm.find('.field.error').length > 0) {
			$('.payment-declined').remove();
			var paymentFormErrorMessages = opcpayment.getErrorMessages($paymentForm);
			var paymentFormErrorBox = opcpayment.errorBox('Cannot Proceed', 'Please fix the following errors:', paymentFormErrorMessages);
			if (paymentFormErrorBox) {
				$submitPayment.after(paymentFormErrorBox);
			}
		}
		
		if (placeOrderRecaptcha != undefined) {
			grecaptcha.reset(placeOrderRecaptcha);
		}
		
		if (placeOrderRecaptchaPaypal != undefined) {
			grecaptcha.reset(placeOrderRecaptchaPaypal);
		}
		
		if (placeOrderRecaptchaAffirm != undefined) {
			grecaptcha.reset(placeOrderRecaptchaAffirm);
		}
	}
}

function submitPaymentPrivateLabel() {
	var balanceDue = parseFloat($paymentForm.find('[balance-due]').attr('balance-due'));
	$submitPrivateLabelPayment.loading({
		IsImageShown: false,
		Label: Resources.Loading.Checkout.PlacingOrder
	});
	
	setPlaceOrderButtonsDisabled(true);
	
	var isBillingAddressValid = false;
	var isShippingAddressValid = false;
	
	$billingAddressForm.validate().settings.ignore = [];
	$shippingAddressForm.validate().settings.ignore = [];
	
	if ($billingAddressForm.valid()) {
		isBillingAddressValid = true;
	} else {
		$('.billing-address-data.form-info').hide();
		
		$billingAddressForm.show();
		$billingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if ($shippingAddressForm.valid()) {
		isShippingAddressValid = true;
	} else {
		$('.shipping-address-data.form-info').hide();
		
		$shippingAddressForm.show();
		$shippingAddressForm.find('input.required.field.error').eq(0).focus();
	}
	
	if (isBillingAddressValid && isShippingAddressValid && $('#shipping-method-list input:checked').length && (balanceDue == 0 || $paymentForm.valid())) {
		$('.payment-declined').remove();
		
		var actionName = $('#submitOPCPaymentPrivateLabel').attr('name');
		setOPCPaymentData($paymentForm, actionName).then(function (response) {
			var data = JSON.parse(response);

			if (data.hasOwnProperty('status') && data.status === 'success') {
				console.log('payment datalayer');
				GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo').then(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				}).catch(function() {
					if (options.CheckoutMode == 'MobileApp') {
						window.location.href = Urls.confirmationPageForMobileApp;
					} else {
						window.location.href = Urls.confirmationPage;
					}
				});
			} else if (data.hasOwnProperty('status') && data.status === 'shippingRestrictionsError'){
				var popup = require('../popup');
				var payload = JSON.parse(data.payload);

				var list = $.map(payload.list, function (el) {
					return '<li>' + el.name + ':<br>' + el.message + '</li>';
				}).join('');

				popup.init({
					title: payload.title,
					content: payload.message + '<ul class="shipping-restrictions-popup__list">' + list + '</ul>',
				}).showPopup();
			} else {
				var message = Resources.Checkout.Confirm.Error.Generic;
				if (data.hasOwnProperty('errorMessage')) {
					message = data.errorMessage;
				} else if(data.hasOwnProperty('message')) {
					message = data.message;
				}
                $('#submitOPCPaymentPrivateLabel').after('<div id="errorBoxOPC" class="payment-declined p-2 mt-2" role="alert" tabindex="0"><i class="fa fa-exclamation-triangle"></i> <b>Payment Declined:</b> ' + message + '</div>');
			}
			$submitPrivateLabelPayment.loading('destroy');
			setPlaceOrderButtonsDisabled(false);
			if (placeOrderRecaptchaPrivateLabel != undefined) {
				grecaptcha.reset(placeOrderRecaptchaPrivateLabel);
			}
		}.bind(this));
	} else {
		$submitPrivateLabelPayment.loading('destroy');
		setPlaceOrderButtonsDisabled(false);

		// Adding ADA compliance error messages for submitPayment section
		$paymentForm.validate().form();
		if ($paymentForm.find('.field.error').length > 0) {
			$('.payment-declined').remove();
			var paymentFormErrorMessages = opcpayment.getErrorMessages($paymentForm);
			var paymentFormErrorBox = opcpayment.errorBox('Cannot Proceed', 'Please fix the following errors:', paymentFormErrorMessages);
			if (paymentFormErrorBox) {
				$submitPrivateLabelPayment.after(paymentFormErrorBox);
			}
		}

		if (placeOrderRecaptchaPrivateLabel != undefined) {
			grecaptcha.reset(placeOrderRecaptchaPrivateLabel);
		}
	}
}

function initializePaymentMethodsEvents () {
	paymentMethodDomChanges();

	$(document).on('click', creditCardListSelector, function () {
		$creditCardNumberInput.parent().find('.cc-image').css('background-image', 'url(' + $(this).next().attr('src') + ')');
		setPlaceOrderButtonsDisabled(false);
		$(this).closest('div.form-row').find('div.field-wrapper.error').remove();
	});
	
	$(document).on('click', paymentFormSelector + ' .payment-method-selector', function (e) {
	    var paymentMethodId = ('' + $(this).attr('id')).replace('is-','');
	    
	    if (paymentMethodId) {
			paymentMethodDomChanges();

	    	$('.pt_onepcheckout input[name*="_selectedPaymentMethodID"]').val(paymentMethodId);
			$paymentForm.attr('selected-payment-method', paymentMethodId);
	    	
		    $paymentForm.find('.payment-method').each(function() {
		        $(this).removeClass('payment-method-expanded');
		        
		        if ($(this).attr('data-method') == paymentMethodId) {
		            $(this).addClass('payment-method-expanded');
		        }
		    });
		    
		    var $paymentMethodPanel = $('.payment-method-options .payment-method-expanded[data-method="' + paymentMethodId + '"]');
		    if ($paymentMethodPanel.attr('payment-card-image')) {
		    	$paymentMethodPanel.find('.cc-image').css('background-image', 'url(' + $paymentMethodPanel.attr('payment-card-image') + ')');
	    	}
	    }
	    
	    if (window.innerWidth <= 767) {
			$([document.documentElement, document.body]).animate({
				// scrollTop: $('.payment-method-expanded').closest('.payment-method-option').offset().top - $('.top-banner').height() + 20
				scrollTop: $('#paymentSection').offset().top - $('.top-banner').height() - 15
			}, 250);
	    }
	    
	    addresses.updateSummary();
	});
	
	$(document).on('keyup', creditCardNumberMaskSelector, function(event) {
		var $currentNumberMask = $(this);
		var $originalCreditCardField = $currentNumberMask.siblings('input[name*="_number"]');
		var creditCardNumber = $currentNumberMask.val().replace(/\s/g, '');
		var selectedCard, $paymentMethodSelected;

		if (!creditCardNumber || creditCardNumber.length == 0) {
			$originalCreditCardField.val('');
		} else {
			selectedCard = util.getCreditCardType(creditCardNumber);
			if (selectedCard) {
				// Dynamically switch between Private Label payment method and Credit Card payment methods
				$paymentMethodSelected = $paymentForm.find('#is-' + selectedCard.PaymentMethod);
				if ($paymentMethodSelected.length && !$paymentMethodSelected.is(':checked')) {
					$paymentMethodSelected.trigger('click');
				}
				// Update Credit Card brand image
				$('#opc-cc-list').find('input[value="' + selectedCard.PaymentCard + '"]').click();
				
				if (selectedCard.PaymentMethod == 'CREDIT_CARD') {
					// Update credit card number input
					$creditCardNumberInput.val(creditCardNumber);
				}
				if (selectedCard.PaymentMethod == 'Alliance_Data') {
					// Upated private label card number
					$privateLabelCardNumberInput.val(creditCardNumber);
				}
			} else {
				$('.cc-cardlist-select :input').removeAttr('checked').removeAttr('selected').val('');
				$(creditCardListSelector).filter('input').prop('checked', false);
				$paymentForm.find('.payment-method[data-method="' + PaymentMethodConstants.CreditCard + '"] .cc-image').css('background-image', '');
			}

			ccFormatting(event, $(creditCardNumberMaskSelector), creditCardNumber, selectedCard);
		}
	});

	$(document).on('blur', creditCardNumberMaskSelector, function(e) {
		var $currentNumberMask = $(this);
		$paymentForm.validate().element($currentNumberMask.siblings('input[name*="_number"]'));
	});

	$(document).on('click', '.add-new-card', function(e){
		e.preventDefault();

		$('input[name^="dwfrm_billing_paymentMethods_creditCard_owner"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_number"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_cvn"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_expiration_month"]').val('');
		$('input[name^="dwfrm_billing_paymentMethods_creditCard_expiration_year"]').val('');
		$('#opc-cc-list :input').removeAttr('checked');
		$('.cc-cardlist-select :input').removeAttr('checked').removeAttr('selected').val('');
		$('.cc-image').css('background-image', 'none');
		$(creditCardNumberMaskSelector).val('');
		paymentMethodDomChanges();
	});

	$(document).off('Onepcheckout.PaymentFormFieldValidation').on('Onepcheckout.PaymentFormFieldValidation', function() {
		// Destroy any previous validators for the $paymentForm before reinitializing due to redraw of checkout
		$paymentForm.validate().destroy();
		// Don't use .form() or any other validator method here because it will auto-check the form immediately, but we want to just attach the listeners
		$paymentForm.validate(validator.settings);
    });
}

function ccFormatting(event, $currentNumberMask, creditCardNumber, selectedCard) {
	try {
		if (!event || !$currentNumberMask || !creditCardNumber) {
			return;
		}
		var cursorPosition = $currentNumberMask.get(0).selectionStart;
		var formattedCardNumber = util.formatCreditCardNumber(selectedCard, creditCardNumber);
		var inputValueSelected = util.inputValueSelected($currentNumberMask.get(0));
		var keyCode = event.keyCode;
		var controlKeys = ['9', '16', '17', '37', '38', '39', '40'];
		var controlKeyIndex = controlKeys.indexOf(keyCode.toString());

		if (formattedCardNumber && creditCardNumber.indexOf('*') < 0) {
			$(creditCardNumberMaskSelector).val(formattedCardNumber); 
			if (!inputValueSelected && controlKeyIndex < 0) {
				cursorPosition = formattedCardNumber.charAt(cursorPosition - 1) === ' ' ? (cursorPosition + 1) : cursorPosition;
				if (formattedCardNumber.length >= 17) {
					cursorPosition = formattedCardNumber.length;
				}
				util.setCursorPosition($currentNumberMask.get(0), cursorPosition);
			}
		} else {
			$(creditCardNumberMaskSelector).val(creditCardNumber);
		}
	} catch (error) {
		// Do nothing on JS error 
		// Example error: ctrl + alpha key causes event.keyCode to be undefined
	}
}

function paymentMethodDomChanges() {
	var combinedSelectors = [creditCardNumberInputSelector, privateLabelCardNumberInputSelector].join();
	var $fieldContainer = $(combinedSelectors).parent();

	if ($(creditCardListSelector + ':checked').val()) {
		$(creditCardListSelector + ':checked').trigger('change');
	}

	$(combinedSelectors).attr('tabindex','-1').val('');
	$paymentForm.attr('selected-payment-method', $('.input-radio.payment-method-selector:checked').val());
	$creditCardNumberMask.val(''); // Clear CC value from mask before appending to element
	$('.cc-image').css('background-image', ''); // Clear CC image icon from CSS before appending to element
	
	if ($fieldContainer.find('.cc-image').length == 0) {
		$fieldContainer.append($creditCardImage);
	}
	if ($fieldContainer.find(creditCardNumberMaskSelector).length == 0) {
		$fieldContainer.append($creditCardNumberMask);
	}
}


function setPlaceOrderButtonsDisabled(isDisabled) {
	$submitPayment.attr('disabled', isDisabled);	
	$('#submitOPCPaymentPrivateLabel').attr('disabled', isDisabled);	
}

/**
 * @description Make the AJAX request to set cart data
 * @param {Element} form The form element that present address object
 * @param {Element} Action name to apply to form
 * @returns {Promise}
 */
var setOPCPaymentData = function (form, name) {
    var $form = $(form);

    return TPromise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.setOpcAddress),
        data: $form.serialize() + '&' + name + '=' + name
    }));
};

exports.init = function (optionsParameter) {
	options = optionsParameter;
	
	if (options && options.Recaptcha && (options.Recaptcha.EnabledForPlaceOrder || options.Recaptcha.EnabledForRewardsCredit || options.Recaptcha.EnabledForGiftCards)) {
    	recaptcha.initialize();
	}
	GiftCard.initialize(options);
	RewardsCredit.initialize(options);
	LoyaltyCredit.initialize(options);
    initializeEvents(options);
};